import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useFewShotTaggingModelApi } from "./Api";
import { Breadcrumb } from "flowbite-react";
import { YMIframe } from "../project/components/components";
import { YMCard, YMLoader } from "../../ym-components";

const useModelId = () => {
  const [searchParams, _] = useSearchParams();
  const [modelId, setModelId] = useState("" as string | undefined);
  const [modelName, setModelName] = useState("" as string);
  // moke model name to display in breadcrumb
  useEffect(() => {
    if (searchParams.get("name")) {
      setModelName(searchParams.get("name") as string);
    }
    if (searchParams.get("id")) {
      setModelId(searchParams.get("id") as string);
    }
  }, [searchParams]);

  return { modelId, modelName };
};

export default function FewShotLearningModelView(props: any) {
  const { modelId, modelName } = useModelId();
  const api = useFewShotTaggingModelApi();
  const navigate = useNavigate();

  const model = useQuery({
    queryKey: ["fewshot-learning-model", modelId],
    queryFn: async () => {
      if (modelId) {
        return api.getModel(modelId);
      }
    },
    enabled: !!modelId,
  });

  return (
    <div>
      <Breadcrumb className="bg-gray-50 py-3 px-5 dark:bg-gray-900">
        <Breadcrumb.Item>
          <a
            className={"cursor-pointer hover:text-indigo-900"}
            onClick={() => navigate("/few_shot_learning_models")}
          >
            {" "}
            Few Shot Learning Models{" "}
          </a>
        </Breadcrumb.Item>
        {modelId && (
          <Breadcrumb.Item className={"capitalize"}>
            {modelName}
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
      <YMLoader
        message={"Chargement du modèle"}
        loading={model.isFetching && !model.isFetched}
      >
        <div>
          <YMCard>
            <h3 className="text-2xl font-bold mb-2 mt-4 text-indigo-900">
              {" "}
              Carte des verbatims{" "}
            </h3>
            <p className={"text-indigo-800 max-w-4xl"}>
              Cette carte permet de se représenter visuellement les verbatims
              d'entraînement tel que vu par le modèle de classification.
            </p>
            <YMIframe
              src={model.data?.trainingDataMap}
              style={{ width: "100%", height: "700px" }}
            />
          </YMCard>
        </div>
        <div>
          <YMCard>
            <h3 className="text-2xl font-bold mb-2 mt-4 text-indigo-900">
              {" "}
              F1 scores, precision, et recall{" "}
            </h3>
    <div className="overflow-x-auto">
      <a href={model.data?.RawData} className="text-indigo-900 underline"> Télécharger les données brutes </a>
      <table className="min-w-full border-collapse border border-gray-300">
        <thead className="bg-gray-200">
          <tr>
            <th className="px-4 py-2 border border-gray-300 text-indigo-900 text-left text-sm font-medium">Critère</th>
            <th className="px-4 py-2 border border-gray-300 text-indigo-900 text-left text-sm font-medium">F1 Score</th>
            <th className="px-4 py-2 border border-gray-300 text-indigo-900 text-left text-sm font-medium">Rappel</th>
            <th className="px-4 py-2 border border-gray-300 text-indigo-900 text-left text-sm font-medium">Précision</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-300">
          <tr>
            <td className="px-4 py-2 border border-gray-300 text-sm font-medium">Signification</td>
            <td className="px-4 py-2 border border-gray-300 text-sm">
              Moyenne harmonique de la précision et du rappel, indiquant l'équilibre global entre la capacité à identifier tous les cas positifs et la fiabilité des prédictions positives.
            </td>
            <td className="px-4 py-2 border border-gray-300 text-sm">
              Mesure la capacité du modèle à détecter l'ensemble des cas positifs présents dans les données.
            </td>
            <td className="px-4 py-2 border border-gray-300 text-sm">
              Indique la proportion des prédictions positives qui sont effectivement correctes.
            </td>
          </tr>
          <tr>
            <td className="px-4 py-2 border border-gray-300 text-sm font-medium">Valeur à 0</td>
            <td className="px-4 py-2 border border-gray-300 text-sm">
              Un F1 score de 0 signifie une performance globale très mauvaise, c'est-à-dire que le modèle ne parvient ni à identifier correctement les cas positifs ni à réaliser de bonnes prédictions.
            </td>
            <td className="px-4 py-2 border border-gray-300 text-sm">
              Un rappel de 0 indique que le modèle n'a détecté aucun des cas positifs réels, manquant ainsi tous les exemples attendus.
            </td>
            <td className="px-4 py-2 border border-gray-300 text-sm">
              Une précision de 0 signifie qu'aucune des prédictions positives effectuées par le modèle n'est correcte (seuls des faux positifs sont générés).
            </td>
          </tr>
          <tr>
            <td className="px-4 py-2 border border-gray-300 text-sm font-medium">Valeur à 1</td>
            <td className="px-4 py-2 border border-gray-300 text-sm">
              Un F1 score de 1 reflète une performance parfaite, avec une capacité optimale à identifier tous les cas positifs et à éviter les fausses prédictions.
            </td>
            <td className="px-4 py-2 border border-gray-300 text-sm">
              Un rappel de 1 signifie que le modèle a détecté <strong>tous</strong> les cas positifs réels, sans en manquer aucun.
            </td>
            <td className="px-4 py-2 border border-gray-300 text-sm">
              Une précision de 1 signifie que <strong>toutes</strong> les prédictions positives sont correctes, sans aucune erreur.
            </td>
          </tr>
        </tbody>
      </table>
    </div><div className={"mt-10"}><YMIframe
              src={model.data?.evaluationMetricsPage}
              style={{ width: "100%", height: "700px" }}
            /></div>

          </YMCard>
        </div>
        <div>
          <YMCard>
            <h3 className="text-2xl font-bold mb-2 mt-4 text-indigo-900">
              Matrice de confusion
            </h3>
            <a href={model.data?.ConfusionMatrix} target={"_blank"} > Voir dans un nouvel onglet </a>
            <YMIframe
              src={model.data?.ConfusionMatrix}
              style={{ width: "100%", height: "700px" }}
            />
          </YMCard>
        </div>
        <div>
          <YMCard>
            <h3 className="text-2xl font-bold mb-2 mt-4 text-indigo-900">
              Courbe rappel / précision
            </h3>
            <p className={"text-indigo-800 max-w-4xl"}>La courbe rappel/précision est un graphique essentiel pour évaluer la performance d’un modèle de classification. Sur l’axe horizontal, le rappel indique la capacité à identifier l’ensemble des cas positifs, tandis que l’axe vertical mesure la précision, c’est-à-dire le pourcentage de prédictions positives correctes. Chaque point sur la courbe correspond à un seuil de décision particulier, illustrant le compromis entre détecter tous les positifs et limiter les fausses alertes. Un rappel élevé signifie que le modèle ne manque que très peu de cas positifs. Une précision élevée montre que la plupart des prédictions positives sont exactes. L’idéal est d’obtenir une courbe qui se rapproche du coin supérieur droit du graphique. Cela reflète un équilibre optimal entre la capacité à détecter tous les cas positifs et la fiabilité des prédictions. Ce graphique est particulièrement utile sur des jeux de données déséquilibrés. Il permet de visualiser rapidement comment la performance varie avec le seuil de décision. En résumé, la courbe rappel/précision aide à choisir le seuil optimal pour atteindre le meilleur compromis entre sensibilité et exactitude.</p>
            <YMIframe
              src={model.data?.F1ScoreHeatMap}
              style={{ width: "100%", height: "700px" }}
            />
          </YMCard>
        </div>
        <div>
          <YMCard>
            <h3 className="text-2xl font-bold mb-2 mt-4 text-indigo-900">
              Heatmap des F1 scores
            </h3>
            <YMIframe
              src={model.data?.PrecisionRecallCurve}
              style={{ width: "100%", height: "700px" }}
            />
          </YMCard>
        </div>
      </YMLoader>
    </div>
  );
}
