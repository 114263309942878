import { Button, Spinner } from "flowbite-react";
import { YMButton, YMTable } from "../../ym-components";
import React from "react";
import { useProjectFewShotLearningModels } from "./Model";
import { useFewShotTaggingModelApi } from "./Api";
import { NewModelForm } from "./components/NewModel";
import readXlsxFile from "read-excel-file";
import { notificationError, notificationInfo } from "../../toast-notification";
import { AiOutlineDownload } from "react-icons/ai";
import { IoReloadOutline } from "react-icons/io5";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { FewShotTaggingModelStatus } from "../../app_client";
import { HiTrash } from "react-icons/hi";
import { BsFillGearFill } from "react-icons/bs";

export default function FewShotLearningModels(props: any) {
  const [displayAllClass, setDisplayAllClass] = React.useState("");
  const navigate = useNavigate();

  const api = useFewShotTaggingModelApi();
  const model = useProjectFewShotLearningModels({
    api,
    readXLSX: async (file: File) => {
      let data = await readXlsxFile(file);
      return data.map((r) => r.map((c) => `${c}`));
    },
    notifyError: (message: string) => {
      notificationError(message);
    },
    notifyInfo: (message: string) => {
      notificationInfo(message);
    },
  });
  let { _models } = model;

  return (
    <div>
      <NewModelForm {...model} />
      <div>
        {_models.isInitialLoading ? (
          <div className="w-100 mt-10 text-center">
            <Spinner
              color="gray"
              size="xl"
              aria-label="Default status example"
            />
            <div className="mt-4 text-gray-400">Fetching models</div>
          </div>
        ) : model.state.WorkspaceModelsComputed.length === 0 ? (
          <div className="w-100 mt-5 text-center">
            <p className="text-gray-500 mb-4">
              No fewshot tagging models available.
            </p>
          </div>
        ) : (
          <div className="relative overflow-x-auto rounded-lg border border-gray-200 bg-white shadow-lg mt-5">
            <YMTable
              header={
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Model
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Classes d'entrainement
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Type de classification
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Modèle d'embedding
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Créé le
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Lien Beam
                  </th>
                  <th scope="col" className="px-6 py-3">
                    <span className="sr-only"> x </span>
                  </th>
                  <th scope="col" className="px-6 py-3">
                    <span className="sr-only"> x </span>
                  </th>
                </tr>
              }
              rows={model.state.WorkspaceModelsComputed.map((m) => (
                <tr
                  key={m.model_id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100"
                >
                  <td className="px-6 py-4 text-indigo-900 cursor-pointer capitalize font-bold max-w-[48px] hover:text-indigo-900 dark:text-white">
                    {m.name} <br />
                    <span className={"font-normal text-xs"}>
                      {" "}
                      {statusName(m.status)}{" "}
                    </span>
                  </td>
                  <td className="px-6 py-4 max-w-[48px]">
                    {Object.entries(m.training_example_counter || {})
                      .slice(0, m.model_id != displayAllClass ? 5 : undefined)
                      .map(([k, v]) => (
                        <div key={k}>
                          <span className="text-indigo-900 capitalize font-bold">
                            {k}&nbsp;
                          </span>
                          <span className="text-indigo-800 text-xs">{v}</span>
                        </div>
                      ))}
                    {Object.entries(m.training_example_counter || {}).length >
                      5 &&
                      displayAllClass !== m.model_id && (
                        <a
                          className={"text-indigo-900 cursor-pointer"}
                          onClick={() => setDisplayAllClass(m.model_id)}
                        >
                          voir toutes les classes ...{" "}
                        </a>
                      )}
                    {displayAllClass === m.model_id && (
                      <a
                        className={"text-indigo-900 cursor-pointer"}
                        onClick={() => setDisplayAllClass("")}
                      >
                        cacher ...{" "}
                      </a>
                    )}
                  </td>
                  <td className="px-6 py-4">{m.classification_method}</td>
                  <td className="px-6 py-4">{m.embedding_model}</td>
                  <td className="px-6 py-4">
                    {m.created_at &&
                      DateTime.fromISO(m.created_at).toLocaleString()}
                  </td>
                  <td className="px-6 py-4">
                    {m.remote_task_monitoring_url && (
                      <a
                        href={m.remote_task_monitoring_url}
                        target="_blank"
                        className="text-blue-600"
                        rel="noreferrer"
                      >
                        Voir sur Beam
                      </a>
                    )}
                  </td>
                  <td className={"px-6 py-4"}>
                    <YMButton
                      text={"Données d'entraînement"}
                      icon={<AiOutlineDownload className={"h-6 w-6 pr-2"} />}
                      size={"xs"}
                      color={"white"}
                      onClick={() => model.actions.onDownloadTrainingData(m)}
                    />
                  </td>
                  <td className="px-6 py-4">
                    <div className={"flex flex-row"}>
                      <Button
                        size="xs"
                        color="gray"
                        onClick={() => model.actions.triggerModelTraining(m)}
                      >
                        <IoReloadOutline className="h-4 w-4" /> Entrainement
                      </Button>
                      <Button
                        size="xs"
                        color="gray"
                        onClick={() => model.actions.triggerModelEvaluate(m)}
                      >
                        <IoReloadOutline className="h-4 w-4" /> Evaluation
                      </Button>
                      <Button
                        size="xs"
                        color="gray"
                        onClick={() =>
                          navigate(
                            `/few_shot_learning_models/edit?id=${m.model_id}&name=${m.name}`
                          )
                        }
                      >
                        <BsFillGearFill className="h-4 w-4" />
                      </Button>
                      <Button
                        size="xs"
                        color="gray"
                        onClick={() => model.actions.disableModel(m)}
                      >
                        <HiTrash className="h-4 w-4" />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const statusName = (status: FewShotTaggingModelStatus) => {
    switch (status) {
        case FewShotTaggingModelStatus.READY:
            return "";
        case FewShotTaggingModelStatus.TRAINING:
            return "Entraînement en cours...";
        case FewShotTaggingModelStatus.EMBEDDING_TRAIN_DATA:
            return "Embedding en cours...";
        case FewShotTaggingModelStatus.TRAINING_REMOTELY:
            return "Entraînement en cours sur beam...";
        default:
            return status;
    }
}